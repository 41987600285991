<template>
  <div class="join_step4">
    <div>
      保证金缴纳
    </div>
    <router-link to="/">
      <el-button type="primary">返回首页</el-button>
    </router-link>
  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    created() {
      this.joinBond()
    },
    methods: {
      joinBond() {
        this.$emit('getActiveStep', 3)
      }
    }
  }
</script>
<style>
  .join_step4 {
    line-height: 150px;
  }
</style>